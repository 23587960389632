<template>
  <router-view></router-view>
</template>

<script>
export default {
  created() {
    // FIX for error in early ABs (links contained two slashes after public in pathname)
    const pathnameWithoutDoubleSlashes = window.location.pathname.replace('//', '/');
    if (window.location.pathname != pathnameWithoutDoubleSlashes)
      window.location.replace(pathnameWithoutDoubleSlashes)

    // by default, set locale to locally stored value
    this.$i18n.locale = window.localStorage.getItem('locale');
    if (!this.$i18n.locale) {
      this.$i18n.locale = 'en-US'; // default language
      // try to set locale to browser language
      for (let locale of navigator.languages) {
        if (this.$i18n.availableLocales.includes(locale)) {
          this.$i18n.locale = locale;
          break;
        } else if (this.$i18n.availableLocales.includes(locale.slice(0, 2))) {
          this.$i18n.locale = locale.slice(0, 2);
          break;
        }
      }
    }
  }
}
</script>

<style>
:root {
  --rom-red: #c91430;
  --rom-gray: #404443;
  --table-hover-bg-color: #e2e3e5;
}

.bg-rom-gray {
  background-color: var(--rom-gray) !important;
  color: white !important;
}

a:not(.dropdown-item):not(.btn) {
  color: var(--rom-red) !important;
}
</style>
