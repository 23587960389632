export default {
  "Service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service"])},
  "ServiceTracker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service-Tracker"])},
  "Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum"])},
  "Search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suche"])},
  "claimsList": {
    "Claim#": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rekl.-Nr."])},
    "Reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referenz"])},
    "Model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modell"])},
    "Status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "ClaimDuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dauer (Tage)"])},
    "store": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filiale"])},
    "noClaims": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In den letzten 6 Monaten waren keine neuen Reklamationen."])},
    "provideCustomerAccountNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte wählen Sie eine Kundennummer aus."])},
    "internalHeader": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Reklamationen von ", _interpolate(_named("customer"))])},
    "createClaim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Beanstandung anlegen"])},
    "createClaimLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.rom1961.com/de/WKD/"])},
    "copyCustomerLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kundenlink kopieren"])},
    "invoiceAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechnungskonto"])},
    "storeAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Ansicht"])},
    "Logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abmelden"])},
    "copyTosLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbandslink kopieren"])},
    "clipboardRichText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Den aktuellen Stand Ihrer Beanstandungen können Sie <a href=\"", _interpolate(_named("url")), "\">HIER</a> einsehen und prüfen."])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text"])}
  },
  "claimDetails": {
    "GeneralInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allgemeine Informationen"])},
    "OurClaim#": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ROM-Reklamationsnummer:"])},
    "Reference:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referenz:"])},
    "Model:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modell:"])},
    "OriginalCase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ursprünglicher Auftrag"])},
    "OriginalCaseNumber:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nummer:"])},
    "OriginalCaseReference:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referenz:"])},
    "OriginalCaseDate:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum:"])},
    "ClaimInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reklamations-Informationen"])},
    "ClaimReason:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reklamationsgrund:"])},
    "Situation:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Situation:"])},
    "Status:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status:"])},
    "ClaimDuration:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dauer der Reklamation:"])},
    "calendarDays": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("days")), " Kalendertag"]), _normalize([_interpolate(_named("days")), " Kalendertage"])])},
    "calendarDaysSoFar": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["seit heute"]), _normalize(["seit gestern"]), _normalize([_interpolate(_named("days")), " Kalendertage bisher"])])},
    "Approach:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorgehensweise:"])},
    "PersonInCharge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sachbearbeiter"])},
    "Event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ereignis"])},
    "noEvents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Ereignisse bisher."])},
    "approachSetTo:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorgehensweise festgelegt auf:"])},
    "approachChangedTo:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorgehensweise geändert auf:"])},
    "trackingLinkText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paket verfolgen"])},
    "contactService": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service kontaktieren"])}
  },
  "chooseCustomerTos": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunden auswählen"])},
    "dataAreaId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mandant"])},
    "customerAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kundennr."])},
    "customerName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "fullPrimaryAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])},
    "All": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle"])},
    "Customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunde"])},
    "TOS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verband"])},
    "tosId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
    "tosName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])}
  },
  "internalClaimsList": {
    "linkCopiedToClipboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link wurde in der Zwischenablage abgelegt."])},
    "allClaims": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Reklamationen"])}
  }
}