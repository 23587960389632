import { createRouter, createWebHistory } from 'vue-router'

function lazyLoad(page) {
    return () => import(`./pages/${page}`);
}

const routes = [
    { path: '/internal/', component: lazyLoad('InternalClaimsList'), name: 'internal-without-params'},
    { path: '/internal/all', component: lazyLoad('InternalClaimsList'), name: 'internal-all'},
    { path: '/internal/:dataAreaId/:customerAccountNumber', component: lazyLoad('InternalClaimsList') },
    { path: '/internal/tos/:dataAreaId/:tosId', component: lazyLoad('InternalClaimsList'), name: 'internal-tos' },
    { path: '/public/:hash', component: lazyLoad('CustomerClaimsList'), name: 'customer-link' },
    { path: '/public/tos/:hash', component: lazyLoad('TosClaimsList'), name: 'tos-link'}
];

export default createRouter({
    history: createWebHistory(),
    routes
});