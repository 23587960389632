export default {
  "Service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service"])},
  "ServiceTracker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service Tracker"])},
  "Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum"])},
  "Search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zoeken"])},
  "claimsList": {
    "Claim#": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klachten-nr."])},
    "Reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referentie"])},
    "Model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Model"])},
    "Status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "ClaimDuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duur (Dagen)"])},
    "store": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["filiaal"])},
    "noClaims": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In de laatste 6 maanden zijn er geen nieuwe klachten binnengekomen."])},
    "provideCustomerAccountNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kies een klantnummer a.u.b."])},
    "internalHeader": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Klachten van ", _interpolate(_named("customer"))])},
    "createClaim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuwe klacht indienen"])},
    "createClaimLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.rom1961.com/nl/klachten/"])},
    "copyCustomerLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klantenlink kopiëren"])},
    "invoiceAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["factureringsaccount"])},
    "storeAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deze visie"])},
    "Logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log uit"])},
    "copyTosLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vereniginglink kopiëren"])},
    "clipboardRichText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Je kunt de huidige status van je klachten <a href=\"", _interpolate(_named("url")), "\">HIER</a> bekijken en controleren."])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tekst"])}
  },
  "claimDetails": {
    "GeneralInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algemene informatie"])},
    "OurClaim#": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ROM klachtennummer:"])},
    "Reference:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referentie:"])},
    "Model:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Model:"])},
    "OriginalCase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oorspronkelijke bestelling"])},
    "OriginalCaseNumber:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nummer:"])},
    "OriginalCaseReference:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referentie:"])},
    "OriginalCaseDate:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum:"])},
    "ClaimInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klachtinformatie"])},
    "ClaimReason:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reden voor de klacht:"])},
    "Situation:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Situatie:"])},
    "Status:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status:"])},
    "ClaimDuration:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duur van de klacht:"])},
    "calendarDays": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("days")), " Kalenderdag"]), _normalize([_interpolate(_named("days")), " Kalenderdagen"])])},
    "calendarDaysSoFar": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["sinds vandaag"]), _normalize(["sinds gisteren"]), _normalize([_interpolate(_named("days")), " Kalenderdagen tot nu toe"])])},
    "Approach:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Werkwijze:"])},
    "PersonInCharge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klerk"])},
    "Event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebeurtenissen"])},
    "noEvents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tot nu toe geen gebeurtenissen."])},
    "approachSetTo:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Werkwijze ingesteld op:"])},
    "approachChangedTo:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Werkwijze gewijzigd in:"])},
    "trackingLinkText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Track pakket"])},
    "contactService": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact service"])}
  },
  "chooseCustomerTos": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klanten kiezen"])},
    "dataAreaId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedrijf"])},
    "customerAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klantnummer"])},
    "customerName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam"])},
    "fullPrimaryAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adres"])},
    "All": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle"])},
    "Customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klant"])},
    "TOS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vereniging"])},
    "tosId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
    "tosName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam"])}
  },
  "internalClaimsList": {
    "linkCopiedToClipboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link werd op het klembord geplaatst."])},
    "allClaims": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle klachten"])}
  }
}