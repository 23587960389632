export default {
  "Service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service"])},
  "ServiceTracker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service Tracker"])},
  "Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
  "Search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
  "claimsList": {
    "Claim#": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Claim#"])},
    "Reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reference"])},
    "Model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Model"])},
    "Status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "ClaimDuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duration (days)"])},
    "store": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["store"])},
    "noClaims": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There were no claims for the past 6 months."])},
    "provideCustomerAccountNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select a customer account number."])},
    "internalHeader": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Claims of ", _interpolate(_named("customer"))])},
    "createClaim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a new claim"])},
    "createClaimLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.rom1961.com/en/claims/"])},
    "copyCustomerLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy customer link"])},
    "invoiceAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["invoicing account"])},
    "Logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log out"])},
    "copyTosLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy TOS link"])},
    "clipboardRichText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You can view and verify the current status of your complaints <a href=\"", _interpolate(_named("url")), "\">HERE</a>."])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text"])}
  },
  "claimDetails": {
    "GeneralInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General information"])},
    "OurClaim#": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ROM claim number:"])},
    "Reference:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reference:"])},
    "Model:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Model:"])},
    "OriginalCase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Original case"])},
    "OriginalCaseNumber:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number:"])},
    "OriginalCaseReference:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reference:"])},
    "OriginalCaseDate:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date:"])},
    "ClaimInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Claim information"])},
    "ClaimReason:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Claim reason:"])},
    "Situation:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Situation:"])},
    "Status:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status:"])},
    "ClaimDuration:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Claim duration:"])},
    "calendarDays": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("days")), " calendar day"]), _normalize([_interpolate(_named("days")), " calendar days"])])},
    "calendarDaysSoFar": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["since today"]), _normalize(["since yesterday"]), _normalize([_interpolate(_named("days")), " calendar days so far"])])},
    "Approach:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approach:"])},
    "PersonInCharge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Person in charge"])},
    "Event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event"])},
    "noEvents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currently no events."])},
    "approachSetTo:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approach set to:"])},
    "approachChangedTo:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approach changed to:"])},
    "trackingLinkText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Track parcel"])},
    "contactService": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact service"])}
  },
  "chooseCustomerTos": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose customer"])},
    "dataAreaId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company"])},
    "customerAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer#"])},
    "customerName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "fullPrimaryAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
    "All": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
    "Customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer"])},
    "TOS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TOS"])},
    "tosId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
    "tosName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])}
  },
  "internalClaimsList": {
    "linkCopiedToClipboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link copied to clipboard."])},
    "allClaims": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All claims"])}
  }
}