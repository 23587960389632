export default {
  "Service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service"])},
  "ServiceTracker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service Tracker"])},
  "Date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
  "Search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher"])},
  "claimsList": {
    "Claim#": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N° de récl."])},
    "Reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Référence"])},
    "Model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modèle"])},
    "Status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut"])},
    "store": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["magasin"])},
    "noClaims": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il n'y avait pas de nouvelles réclamations durant les derniers 6 mois."])},
    "provideCustomerAccountNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez choisir un numéro de client svp."])},
    "internalHeader": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Réclamations de ", _interpolate(_named("customer"))])},
    "createClaim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer une nouvelle réclamation"])},
    "createClaimLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.rom1961.com/fr/sav/"])},
    "copyCustomerLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copier lien client"])},
    "invoiceAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["compte de facturation"])},
    "storeAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette vue"])},
    "Logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se déconnecter"])},
    "ClaimDuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durée (jours)"])},
    "copyTosLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copier lien d'association"])},
    "clipboardRichText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vous pouvez consulter et vérifier l'état actuel de vos réclamations <a href=\"", _interpolate(_named("url")), "\">ICI</a>."])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Texte"])}
  },
  "claimDetails": {
    "GeneralInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations générales"])},
    "OurClaim#": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de réclamation ROM :"])},
    "Reference:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Référence :"])},
    "Model:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modèle :"])},
    "OriginalCase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commande initiale"])},
    "OriginalCaseNumber:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro :"])},
    "OriginalCaseReference:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Référence :"])},
    "OriginalCaseDate:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date :"])},
    "ClaimInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations concernants la réclamation"])},
    "ClaimReason:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raison de la réclamation :"])},
    "Situation:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Situation :"])},
    "Status:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut :"])},
    "ClaimDuration:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durée de la réclamation:"])},
    "calendarDays": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("days")), " jour calendrier"]), _normalize([_interpolate(_named("days")), " jours calendrier"])])},
    "calendarDaysSoFar": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["depuis aujourd'hui"]), _normalize(["depuis hier"]), _normalize([_interpolate(_named("days")), " jours calendrier jusqu'à présent"])])},
    "Approach:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approche :"])},
    "PersonInCharge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Responsable"])},
    "Event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Événement"])},
    "noEvents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas d'événements pour l'instant."])},
    "approachSetTo:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approche définie comme :"])},
    "approachChangedTo:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approche changée vers :"])},
    "trackingLinkText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suivi du colis"])},
    "contactService": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacter le service"])}
  },
  "chooseCustomerTos": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choix du client"])},
    "dataAreaId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entreprise"])},
    "customerAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N° de client"])},
    "customerName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
    "fullPrimaryAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])},
    "All": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous"])},
    "Customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client"])},
    "TOS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Association"])},
    "tosId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
    "tosName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])}
  },
  "internalClaimsList": {
    "linkCopiedToClipboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le lien a été enregistré dans votre presse-papiers."])},
    "allClaims": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toutes les réclamations"])}
  }
}