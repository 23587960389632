import { createApp } from 'vue'
import App from './App.vue'

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import { faAngleDown, faAngleUp, faArrowLeft, faArrowRightFromBracket, faArrowsRotate, faBars, faMagnifyingGlass, faMinus, faPlus,
         faSpinner, faUser, faGlobe, faArrowUp, faXmark, faCaretRight, faCaretDown, faEnvelope, faPen, faBuilding } from '@fortawesome/free-solid-svg-icons'
import { faCopy } from '@fortawesome/free-regular-svg-icons'

/* add icons to the library */
library.add(
    faMagnifyingGlass, faPlus, faMinus, faSpinner, faAngleUp, faAngleDown, faArrowsRotate, faBars, faArrowLeft, faArrowRightFromBracket, faUser, faCopy, faGlobe,
    faArrowUp, faXmark, faCaretRight, faCaretDown, faEnvelope, faPen, faBuilding
);

// Bootstrap (https://getbootstrap.com/)
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"

// vue-i18n
import i18n from './i18n'

// vue-router
import router from './router'

// vue-virtual-scroller
import VueVirtualScroller from 'vue-virtual-scroller'
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'

const app = createApp(App);
app.use(i18n)
.use(router)
.use(VueVirtualScroller)
.component('font-awesome-icon', FontAwesomeIcon)
app.config.unwrapInjectedRef = true;

app.mount('#app');
